<template>
    <b-modal
        id="patient-detail-preview"
        size="lg"
        hide-header
        centered
        footer-class="patient-preview-footer"
        @show="onModalOpen"
        @hidden="onModalClose"
    >
        <div id="preview-header" class="p-4">
            <b-row>
                <b-col class="py-1" sm="8">
                    <div class="text-center text-sm-left">
                        <p class="pt-2 mb-0 text-white-light">{{ t('patientId') }}</p>
                        <p
                            @click="$router.push(`/patient/${patient.patientId}`)"
                            class="text-big mb-1 word-wrap pointer"
                            id="patient-detail-preview-last-name"
                        >
                            {{ patient.patientRef }}
                        </p>
                        <template v-if="!activeCustomer.hidePatientNames">
                            <p class="mb-1 word-wrap">
                                <span
                                    class="h4 light text-white-light"
                                    id="patient-detail-preview-first-name"
                                    >{{ namesFormatter(patient.lastName, patient.firstName) }}</span
                                >
                            </p>
                            <p
                                v-if="namesFormatter(patient.lastName, patient.firstName)"
                                class="mb-1 word-wrap"
                            >
                                <span class="text-white-light text-caption">
                                    {{ t('patientNameLastFirst') }}</span
                                >
                            </p>
                        </template>
                    </div>
                </b-col>
                <b-col class="py-1" sm="4">
                    <div class="float-sm-right text-center">
                        <p class="pt-2 mb-0 text-white-light">
                            {{ patient.dateOfBirth | date({isUTC: false}) }}
                        </p>
                        <p class="pt-0 text-display light">{{ age }} {{ t('yrs') }}</p>
                    </div>
                </b-col>
            </b-row>
            <hr class="my-4 horizontal-line" />
            <b-row>
                <b-col sm="9">
                    <div class="d-sm-inline-flex text-center">
                        <div>
                            <p
                                class="text-caption heavy text-white-lighter surgeon-line text-sm-left"
                            >
                                {{ t('surgeon').toUpperCase() }}
                            </p>
                            <p class="text-display light mb-0 text-center text-sm-left">
                                {{
                                    surgeonIdNameFormat(
                                        patient.ocosDoctorId,
                                        patient.doctorFullName,
                                        patient.doctorActive
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3" class="text-center">
                    <div class="d-inline-flex d-sm-inline-block float-sm-right text-center">
                        <div
                            class="text-caption text-gray-darker py-2"
                            :class="{heavy: patient.active}"
                        >
                            <b-icon
                                :icon="patient.active ? 'check' : 'X'"
                                class="pr-1"
                                :class="patient.active ? 'text-success' : 'text-gray-dark'"
                                scale="2"
                            ></b-icon>
                            <span class="text-gray-light text-caption heavy">
                                {{ t('active') }}
                            </span>
                        </div>
                        <div
                            class="text-caption text-gray-darker py-2 ml-3 ml-sm-0"
                            :class="{heavy: patient.priority}"
                        >
                            <b-icon
                                :icon="patient.priority ? 'check' : 'X'"
                                class="pr-1"
                                :class="patient.priority ? 'text-success' : 'text-gray-dark'"
                                scale="2"
                            ></b-icon>
                            <span class="text-gray-light text-caption heavy">
                                {{ t('priority') }}
                            </span>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>

        <PreOpDataAndCalc
            :preOpDataSets="currentPatientPreOpData ? currentPatientPreOpData.preOpDataSets : {}"
            :patient="patient"
            :scrollYWhenRecords="2"
        />
        <hr style="border-color: var(--primary)" class="mt-2 w-100" />

        <h5 class="heavy text-primary mb-2 text-uppercase font-weight-bold">
            {{ t('orders') }} / {{ t('reserve') }}
        </h5>

        <b-table
            class="no-outer-padding lenses-table"
            :fields="fields"
            :items="lenses"
            sticky-header="246px"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: colWidths[field.key]}"
                />
            </template>
            <template #cell(updated)="data">
                {{ data.value | date }}
            </template>
            <template #cell(ocosSalesOrderId)="{value, item}">
                <router-link
                    :to="{
                        name: item.followUpOrderHeaderId ? 'ReserveDetails' : pageName[item.status],
                        params: {orderId: item.orderHeaderId.toString()},
                    }"
                    class="record-link text-primary-dark mb-1"
                >
                    {{ value }}
                </router-link>
            </template>
            <template #cell(targetLensDescription)="{item, value}">
                <p class="text-nowrap text-caption heavy text-black mb-1 pl-0 ml-0">
                    <b-img :src="imageSrc(item.opEye)" class="pr-1"></b-img>
                    {{ decimalSeparatorFormatter(value.model, decimalSeparator) }}
                </p>
                <p class="text-caption light text-gray-dark mb-0">
                    {{ lensDescriptionObjToString(value, decimalSeparator) }}
                </p>
            </template>
            <template #cell(reservedLensDescription)="{value}">
                <LensModel :lensDescriptionDetails="value" />
                <div class="d-flex">
                    <LensPrescription :lensDescriptionDetails="value" />
                    <span
                        v-if="value.serial"
                        class="text-caption light text-gray-dark mb-0 white-space-pre"
                    >
                        #{{ value.serial }}</span
                    >
                </div>
            </template>
            <template #cell(status)="{value, item}">
                {{ value }} <br />
                <router-link
                    v-if="item.followUpOrderHeaderId && value == OrderSubStatus.ORDERED"
                    :to="{
                        name: 'OrderDetails',
                        params: {orderId: item.followUpOrderHeaderId},
                    }"
                    class="record-link text-primary-dark mb-1"
                >
                    {{ item.followUpOrderHeaderId }}
                </router-link>
            </template>
        </b-table>

        <template #modal-footer>
            <div class="d-flex align-items-start justify-content-between w-100">
                <b-button
                    class="ml-2"
                    variant="gray-dark"
                    @click="goToPatientDetailsEditMode(patient.patientId)"
                    v-if="currentUser.accessPermissions.PatientPersonalData == 'ReadWrite'"
                >
                    {{ t('edit') }} <b-icon icon="pencil" class="ml-1"></b-icon>
                </b-button>
                <b-button
                    variant="gray-dark"
                    class="ml-2"
                    :class="[
                        currentUser.accessPermissions.PatientPersonalData == 'ReadWrite'
                            ? 'ml-2'
                            : 'ml-auto',
                    ]"
                    @click="$bvModal.hide('patient-detail-preview')"
                >
                    <span id="patient-detail-preview-close-button">{{ t('close') }}</span>
                    <b-icon icon="x" class="ml-1"></b-icon>
                </b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>
import {mapMutations, mapGetters} from 'vuex';
import ODIcon from '@/assets/od_icon.svg';
import OSIcon from '@/assets/os_icon.svg';
import {
    namesFormatter,
    surgeonIdNameFormat,
    lensDescriptionObjToString,
    decimalSeparatorFormatter,
} from '@/utilities/formatters';
import {OrderSubStatus} from '@/constants/order';
import PreOpDataAndCalc from '@/views/patientdetails/components/PreOpDataAndCalc.vue';
import LensPrescription from '@/components/LensPrescription';
import LensModel from '@/components/LensModel';

export default {
    name: 'PatientDetailPreview',
    components: {
        PreOpDataAndCalc,
        LensModel,
        LensPrescription,
    },
    props: {
        patient: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            ODIcon,
            OSIcon,
            OrderSubStatus,
            fields: [
                {key: 'updated', label: this.t('date')},
                {key: 'ocosSalesOrderId', label: this.t('number')},
                {key: 'targetLensDescription', label: this.t('targetLens')},
                {
                    key: 'reservedLensDescription',
                    label: `${this.t('ordered')}/${this.t('reserved')} ${this.t('lens')}`,
                },
                {key: 'status', label: this.t('statusNormalCase')},
            ],
            colWidths: {
                updated: '150px',
                ocosSalesOrderId: '100px',
                targetLensDescription: '200px',
                reservedLensDescription: '200px',
                status: '150px',
            },
        };
    },
    methods: {
        namesFormatter,
        surgeonIdNameFormat,
        lensDescriptionObjToString,
        decimalSeparatorFormatter,
        ...mapMutations({
            setPatientPreOpDataAndCalc: 'patient/setPatientPreOpDataAndCalc',
            setPatientLenses: 'patient/setPatientLenses',
        }),
        /* Navigate to the patient detail page, starting in edit mode, and set the flag to show the overlay to false in the Vuex store. */
        goToPatientDetailsEditMode(id) {
            this.$router.push({
                name: 'PatientDetails',
                params: {patientId: id, startInEditMode: true},
            });
        },
        imageSrc(opEye) {
            return this[`${opEye}Icon`];
        },
        onModalClose() {
            this.setPatientPreOpDataAndCalc(null);
            this.setPatientLenses([]);
        },
        async onModalOpen() {
            this.setSpinner(true);
            const requests = [];

            /* Fetch the patient's pre-op data and calculations. */
            requests.push(
                this.$store
                    .dispatch('patient/fetchPatientPreOpDataAndCalc', {
                        patientId: this.patient.patientId,
                    })
                    .catch((err) => {
                        this.$store.commit('showAlert', {
                            response: err.response,
                            fallbackMsg: 'Failed to fetch patient preop data ' + 'and calculations',
                            seconds: 5,
                        });
                    })
            );

            /* Fetch the patient's lenses data. */
            requests.push(
                this.$store
                    .dispatch('patient/fetchPatientLenses', {
                        patientId: this.patient.patientId,
                    })
                    .catch((err) => {
                        this.$store.commit('showAlert', {
                            response: err.response,
                            fallbackMsg: 'Failed to fetch patient lenses',
                            seconds: 5,
                        });
                    })
            );

            try {
                await Promise.allSettled(requests);
            } finally {
                // this.loaded = true;
                this.setSpinner(false);
            }
        },
    },

    computed: {
        ...mapGetters('user', ['activeCustomer', 'currentUser']),
        ...mapGetters('user', ['activeCustomer']),
        ...mapGetters('patient', ['currentPatientPreOpData', 'currentPatientLenses']),
        decimalSeparator() {
            const {decimalSeparator} = this.currentUser;
            return decimalSeparator;
        },
        pageName() {
            return {
                [OrderSubStatus.ORDERED]: 'OrderDetails',
                [OrderSubStatus.RESERVED]: 'ReserveDetails',
                [OrderSubStatus.IN_CART]: 'ReserveDetails',
            };
        },
        lenses() {
            return this.currentPatientLenses.filter(
                (lens) => lens.status != OrderSubStatus.CANCELLED && !lens.followUpOrderHeaderId
            );
        },
        /* Calculate and return the patient's age. */
        age() {
            var diff_ms = Date.now() - new Date(this.patient.dateOfBirth).getTime();
            var age_dt = new Date(diff_ms);
            return Math.abs(age_dt.getUTCFullYear() - 1970);
        },
    },
};
</script>
<style lang="scss" scoped>
.lenses-table {
    margin-right: -16px;
    margin-left: -16px;
}
</style>
