<template>
    <div class="patient-table-container px-0 mx-0">
        <PatientDetailPreview :patient="selectedPatient" />
        <IodReportPopup v-if="modalShow" :modalShow.sync="modalShow" :lensOrderId="lensOrderId" />
        <b-table
            id="table-patient-list"
            class="table-patient-list"
            :items="patientList"
            :fields="fields"
            no-border-collapse
            fixed
            responsive
            sticky-header="55vh"
            :key="tableDataKey"
        >
            <template #head()="data">
                <div class="content">
                    {{ data.label }}
                </div>
            </template>

            <template #head(updated)>
                <div
                    class="content light-border"
                    :class="queryParams.sortBy === sortCategories.ACTIVITY ? 'sortingColumn' : ''"
                    @click="toggleSortOrder(activitySortOrder, 'activitySortOrder')"
                >
                    <div class="d-flex column-explanation">
                        <div>
                            {{ t('activity') }}
                            <div class="mt-1 column-explanation-text">{{ t('dateHint') }}</div>
                        </div>
                        <template v-if="queryParams.sortBy === sortCategories.ACTIVITY">
                            <b-icon
                                icon="triangle-fill"
                                scale="0.4"
                                class="d-inline-block mt-3"
                                :flip-v="activitySortOrder === 'desc'"
                            ></b-icon>
                        </template>
                        <template v-else>
                            <b-img :src="doubleArrow" class="mt-3"></b-img>
                        </template>
                    </div>
                </div>
            </template>

            <template #cell(updated)="{value, item}">
                <span class="text-caption text-gray-dark">
                    <!-- Doesn't appear to be used -->
                    {{ value | date }}
                    <template v-if="!item.active">
                        {{ t('inactive') }}
                    </template>
                </span>
            </template>

            <template #cell(actions)="data">
                <div class="d-flex">
                    <b-icon
                        class="pointer mr-2"
                        icon="pencil-fill"
                        variant="gray-dark"
                        @click="goToPatientDetailsEditMode(data.item.patientId)"
                        v-if="
                            checkPermissions({
                                [PERMISSIONS.CREATE_PATIENT]: PERMISSIONS_VALUES.ENABLED,
                            })
                        "
                    ></b-icon>
                    <b-img
                        class="mr-2"
                        :src="previewIcon"
                        v-b-modal.patient-detail-preview
                        @click="selectedPatient = data.item"
                    ></b-img>
                    <b-img :src="asterisk" v-if="data.item.priority" width="18" height="18"></b-img>
                </div>
            </template>

            <!-- ALLL column -->

            <template #head(expanded)>
                <div class="content heavy-border pointer" @click="allButtonHandler">
                    {{ t('all') }}
                </div>
            </template>

            <template #cell(expanded)="data">
                <div class="d-flex">
                    <b-icon
                        v-if="expandable(data.item)"
                        class="ml-1 pointer"
                        @click="expand(data)"
                        :icon="icon(data)"
                    ></b-icon>
                </div>
            </template>

            <!-- End ALL column -->

            <template #head(name)>
                <div
                    class="content"
                    :class="queryParams.sortBy === sortCategories.PATIENT ? 'sortingColumn' : ''"
                    @click="toggleSortOrder(patientNameSortOrder, 'patientNameSortOrder')"
                >
                    <div class="d-flex column-explanation">
                        <div>
                            {{ t('patientId') }}
                            <div
                                v-if="!activeCustomer.hidePatientNames"
                                class="mt-1 mr-2 column-explanation-text w-100"
                            >
                                {{ t('patientNameLastFirst') }}
                            </div>
                        </div>
                        <template v-if="queryParams.sortBy === sortCategories.PATIENT">
                            <b-icon
                                icon="triangle-fill"
                                scale="0.4"
                                class="inline mt-3"
                                :flip-v="patientNameSortOrder === 'desc'"
                            ></b-icon>
                        </template>
                        <template v-else>
                            <b-img :src="doubleArrow" class="mt-3"></b-img>
                        </template>
                    </div>
                </div>
            </template>

            <template #cell(name)="data">
                <router-link
                    :to="{
                        name: 'PatientDetails',
                        params: {patientId: data.item.patientId},
                    }"
                    class="patient-link text-primary-dark d-block mb-1 text-caption"
                >
                    {{ data.item.patientRef }}
                </router-link>
                <p
                    v-if="!activeCustomer.hidePatientNames"
                    class="text-caption light text-gray-dark mb-0"
                >
                    {{ namesFormatter(data.item.lastName, data.item.firstName) }}
                </p>
            </template>

            <template #cell(dateOfBirth)="data">
                <span class="text-caption text-gray-dark">
                    {{ data.value | date({isUTC: false}) }}
                </span>
            </template>

            <template #head(dateOfBirth)>
                <div class="content">
                    <div class="column-explanation">
                        {{ t('dob') }}
                        <div class="mt-1 column-explanation-text">{{ t('dateHint') }}</div>
                    </div>
                </div>
            </template>

            <template #head(doctorFullName)>
                <div
                    class="content"
                    :class="queryParams.sortBy === sortCategories.SURGEON ? 'sortingColumn' : ''"
                    @click="toggleSortOrder(surgeonSortOrder, 'surgeonSortOrder')"
                >
                    <div class="d-flex column-explanation">
                        <div>
                            {{ t('surgeon') }}
                            <div class="mt-1 column-explanation-text">
                                {{ t('surgeonNameFirstLast') }}
                            </div>
                        </div>
                        <template v-if="queryParams.sortBy === sortCategories.SURGEON">
                            <b-icon
                                icon="triangle-fill"
                                scale="0.4"
                                class="inline mt-3"
                                :flip-v="surgeonSortOrder === 'desc'"
                            ></b-icon>
                        </template>
                        <template v-else>
                            <b-img :src="doubleArrow" class="mt-3"></b-img>
                        </template>
                    </div>
                </div>
            </template>

            <template #cell(doctorFullName)="{item}">
                <div class="text-caption heavy d-flex align-items-start">
                    {{
                        surgeonIdNameFormat(
                            item.ocosDoctorId,
                            `${item.doctorFirstName} ${item.doctorLastName}`,
                            item.doctorActive
                        )
                    }}
                </div>
            </template>

            <template #head(odCalculations)>
                <div class="header-box left d-flex align-items-center pl-2 pt-1">
                    <img src="@/assets/eye_icon_white.svg" />
                    <img src="@/assets/eye_icon_gray_orig.svg" />
                </div>
                <div class="content heavy-border">
                    {{ t('calculations') }}
                </div>
            </template>

            <template #cell(odCalculations)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.odLensDetails,
                        'selectedPrescription',
                        false
                    )"
                    :key="i"
                >
                    <p class="text-caption light text-gray-dark mb-1 pl-0 ml-0">
                        {{
                            decimalSeparatorFormatter(
                                lensDetail['selectedModel'],
                                currentUser.decimalSeparator
                            )
                        }}
                    </p>
                    <div class="d-flex">
                        <p
                            class="text-caption heavy text-black mb-0"
                            :title="lensDetail['selectedPrescriptionString']"
                        >
                            {{
                                decimalSeparatorFormatter(
                                    lensDetail['selectedPrescriptionString'],
                                    currentUser.decimalSeparator
                                )
                            }}
                        </p>
                        <b-button
                            id="printReport"
                            @click="
                                printReport(
                                    getPreOpDataSetId(data.item.preOpDataItems, EyeSides.OD)
                                )
                            "
                            class="pointer btn-print"
                            v-if="
                                hasPatientCalculationPermission &&
                                lensDetail['selectedPrescriptionString']
                            "
                        >
                            <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                        </b-button>
                    </div>
                </div>
                <p
                    v-if="
                        hasPatientCalculationPermission &&
                        !lensRange(data.item.odLensDetails, 'selectedPrescription', false).length &&
                        calculationStatus(data.item.preOpDataItems, EyeSides.OD)
                    "
                    class="text-nowrap text-caption heavy text-black mb-1"
                >
                    {{ calculationStatus(data.item.preOpDataItems, EyeSides.OD) }}
                    <b-button
                        id="printReport"
                        @click="
                            printReport(getPreOpDataSetId(data.item.preOpDataItems, EyeSides.OD))
                        "
                        class="pointer btn-print"
                    >
                        <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                    </b-button>
                </p>
            </template>

            <template #head(odLens)>
                <div class="header-box middle d-flex align-items-center">
                    <span class="text-subheader heavy text-white">OD</span>
                </div>
                <div class="content">
                    <b-row no-gutters>
                        <b-col offset="3" cols="6">
                            {{ t('odLens') }}
                        </b-col>
                    </b-row>
                </div>
            </template>

            <template #cell(odLens)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.odLensDetails,
                        'reservedPrescription',
                        data.item.expanded
                    )"
                    :key="i"
                    class="np lens-nudge min-h-sub-row"
                >
                    <b-row no-gutters>
                        <b-col cols="3">
                            <b-button
                                v-if="showIodButton(lensDetail)"
                                @click="iodBtnClicked(lensDetail)"
                                variant="outline-primary"
                                class="btn-iod"
                            >
                                {{ isSidButton(lensDetail) }}
                            </b-button>
                        </b-col>
                        <b-col cols="9">
                            <div>
                                <p class="text-caption light text-gray-dark mb-1">
                                    {{ lensDetail['reservedModel'] }}
                                </p>
                                <p
                                    class="text-caption heavy text-black mb-0"
                                    :title="lensDetail['reservedPrescriptionString']"
                                >
                                    {{
                                        decimalSeparatorFormatter(
                                            lensDetail['reservedPrescriptionString'],
                                            currentUser.decimalSeparator
                                        )
                                    }}
                                </p>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </template>

            <template #head(odOrder)>
                <div class="header-box right"></div>
                <div class="content">
                    {{ t('order') }}
                </div>
            </template>

            <template #cell(odOrder)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.odLensDetails,
                        'reservedPrescription',
                        data.item.expanded
                    )"
                    :key="i"
                    class="d-flex align-items-start np lens-nudge min-h-sub-row"
                >
                    <div>
                        <p class="text-caption heavy text-black mb-1">
                            {{ lensDetail.orderStatus }} -
                            <router-link
                                :to="{
                                    name: pageName[lensDetail.orderStatus],
                                    params: {orderId: lensDetail.orderNumber.replaceAll('O', '')},
                                }"
                            >
                                {{ lensDetail.orderNumber }}</router-link
                            >
                        </p>
                        <p
                            v-if="lensDetail.shippingProvider"
                            class="text-caption heavy text-black mb-1"
                        >
                            {{ lensDetail.shippingProvider }}: {{ lensDetail.shippingNumber }}
                        </p>
                        <p
                            v-if="lensDetail.serial"
                            class="text-caption light text-gray-dark mb-0 text-nowrap"
                            :title="lensDetail.serial"
                        >
                            {{
                                t(isConsignment(lensDetail) ? 'invLookup_Consignment' : 'serialNum')
                            }}
                            {{ lensDetail.serial }}
                        </p>
                    </div>
                </div>
            </template>

            <template #head(osCalculations)>
                <div class="header-box left"></div>
                <div class="content heavy-border">
                    {{ t('calculations') }}
                </div>
            </template>

            <template #cell(osCalculations)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.osLensDetails,
                        'selectedPrescription',
                        false
                    )"
                    :key="i"
                >
                    <p class="text-caption light text-gray-dark mb-1">
                        {{
                            decimalSeparatorFormatter(
                                lensDetail['selectedModel'],
                                currentUser.decimalSeparator
                            )
                        }}
                    </p>
                    <div class="d-flex">
                        <p
                            class="text-caption heavy text-black mb-0"
                            :title="lensDetail['selectedPrescriptionString']"
                        >
                            {{
                                decimalSeparatorFormatter(
                                    lensDetail['selectedPrescriptionString'],
                                    currentUser.decimalSeparator
                                )
                            }}
                        </p>
                        <b-button
                            id="printReport"
                            @click="
                                printReport(
                                    getPreOpDataSetId(data.item.preOpDataItems, EyeSides.OS)
                                )
                            "
                            class="pointer btn-print"
                            v-if="
                                hasPatientCalculationPermission &&
                                lensDetail['selectedPrescriptionString']
                            "
                        >
                            <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                        </b-button>
                    </div>
                </div>
                <p
                    v-if="
                        hasPatientCalculationPermission &&
                        !lensRange(data.item.osLensDetails, 'selectedPrescription', false).length &&
                        calculationStatus(data.item.preOpDataItems, EyeSides.OS)
                    "
                    class="text-nowrap text-caption heavy text-black mb-1"
                >
                    {{ calculationStatus(data.item.preOpDataItems, EyeSides.OS) }}
                    <b-button
                        id="printReport"
                        @click="
                            printReport(getPreOpDataSetId(data.item.preOpDataItems, EyeSides.OS))
                        "
                        class="pointer btn-print"
                    >
                        <b-icon class="pointer ml-2" icon="printer-fill"></b-icon>
                    </b-button>
                </p>
            </template>

            <template #head(osLens)>
                <div class="header-box middle d-flex align-items-center">
                    <span class="text-subheader heavy text-white">OS</span>
                </div>
                <div class="content">
                    <b-row no-gutters>
                        <b-col offset="3" cols="6">
                            {{ t('osLens') }}
                        </b-col>
                    </b-row>
                </div>
            </template>

            <template #cell(osLens)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.osLensDetails,
                        'reservedPrescription',
                        data.item.expanded
                    )"
                    :key="i"
                    class="np lens-nudge min-h-sub-row"
                >
                    <b-row no-gutters>
                        <b-col cols="3">
                            <b-button
                                v-if="showIodButton(lensDetail)"
                                @click="iodBtnClicked(lensDetail)"
                                variant="outline-primary"
                                class="btn-iod"
                            >
                                {{ isSidButton(lensDetail) }}
                            </b-button>
                        </b-col>
                        <b-col cols="9">
                            <div>
                                <p class="text-caption light text-gray-dark mb-1">
                                    {{ lensDetail['reservedModel'] }}
                                </p>
                                <p
                                    class="text-caption heavy text-black mb-0"
                                    :title="lensDetail['reservedPrescriptionString']"
                                >
                                    {{
                                        decimalSeparatorFormatter(
                                            lensDetail['reservedPrescriptionString'],
                                            currentUser.decimalSeparator
                                        )
                                    }}
                                </p>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </template>

            <template #head(osOrder)>
                <div
                    class="header-box right d-flex align-items-center justify-content-end pr-2 pt-1"
                >
                    <img src="@/assets/eye_icon_gray_orig.svg" />
                    <img src="@/assets/eye_icon_white.svg" />
                </div>
                <div class="content">
                    {{ t('order') }}
                </div>
            </template>

            <template #cell(osOrder)="data">
                <div
                    v-for="(lensDetail, i) in lensRange(
                        data.item.osLensDetails,
                        'reservedPrescription',
                        data.item.expanded
                    )"
                    :key="i"
                    class="d-flex align-items-start np lens-nudge min-h-sub-row"
                >
                    <div>
                        <p class="text-caption heavy text-black mb-1">
                            {{ lensDetail.orderStatus }} -
                            <router-link
                                :to="{
                                    name: pageName[lensDetail.orderStatus],
                                    params: {orderId: lensDetail.orderNumber.replaceAll('O', '')},
                                }"
                            >
                                {{ lensDetail.orderNumber }}</router-link
                            >
                        </p>
                        <p
                            v-if="lensDetail.shippingProvider"
                            class="text-caption heavy text-black mb-1"
                        >
                            {{ lensDetail.shippingProvider }}: {{ lensDetail.shippingNumber }}
                        </p>
                        <p
                            v-if="lensDetail.serial"
                            class="text-caption light text-gray-dark mb-0"
                            :title="lensDetail.serial"
                        >
                            {{
                                t(isConsignment(lensDetail) ? 'invLookup_Consignment' : 'serialNum')
                            }}
                            {{ lensDetail.serial }}
                        </p>
                    </div>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex';
import {get, isEmpty} from 'lodash';
import {Status, EyeSides} from '@/store/modules/preopdata';
import preview_icon from '@/assets/eye_icon_patient_list.svg';
import od_icon from '@/assets/od_icon.svg';
import os_icon from '@/assets/os_icon.svg';
import double_arrow_icon from '@/assets/double-arrows.svg';
import asterisk from '@/assets/asterisk.svg';
import {SortCategories} from '@/constants/patientlist';
import {OrderSubStatus} from '@/constants/order';
import {
    namesFormatter,
    surgeonIdNameFormat,
    decimalSeparatorFormatter,
} from '@/utilities/formatters';
import PatientDetailPreview from './PatientDetailPreview.vue';
import IodReportPopup from '@/views/iodreport/IodReportPopup.vue';
import {InventorySource} from '@/constants/inventory';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'PatientTable',
    components: {
        IodReportPopup,
        PatientDetailPreview,
    },
    props: {
        queryParams: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            PERMISSIONS,
            PERMISSIONS_VALUES,
            OrderSubStatus,
            EyeSides,
            lensOrderId: null,
            modalShow: false,
            tableDataKey: 0,
            allExpanded: false,

            /* Icons */
            previewIcon: preview_icon,
            odIcon: od_icon,
            osIcon: os_icon,
            doubleArrow: double_arrow_icon,
            asterisk,

            selectedPatient: {},

            /* Pseudo-'enum' that stores the string that will be sent to the backend for each sortable category. */
            sortCategories: SortCategories,

            // Current sort orders for each category
            activitySortOrder: 'desc',
            patientNameSortOrder: 'desc',
            surgeonSortOrder: 'desc',

            fields: [
                {
                    key: 'updated',
                    label: 'Activity',
                    tdClass: 'light-border',
                    class: 'col-updated',
                },
                {key: 'actions', label: '', class: 'col-actions'},
                {
                    key: 'name',
                    label: 'Patient Id',
                    stickyColumn: true,
                    class: 'col-name',
                },
                {key: 'dateOfBirth', label: 'DOB', class: 'col-dob'},
                {key: 'doctorFullName', label: 'Surgeon', class: 'col-doctor'},
                {
                    key: 'odCalculations',
                    label: 'Calculations',
                    tdClass: 'heavy-border',
                    class: 'col-calc',
                },
                {key: 'odLens', label: 'Lens OD', class: 'col-lens'},
                {key: 'odOrder', label: 'Order', class: 'col-order'},
                {
                    key: 'osCalculations',
                    label: 'Calculations',
                    tdClass: 'heavy-border',
                    class: 'col-calc',
                },
                {key: 'osLens', label: 'Lens OS', class: 'col-lens'},
                {key: 'osOrder', label: 'Order', class: 'col-order'},
                {key: 'expanded', label: 'All', tdClass: 'heavy-border', class: 'col-expanded'},
            ],
        };
    },

    computed: {
        ...mapGetters('user', ['activeCustomer', 'currentUser']),
        ...mapGetters('user', ['activeCustomer']),
        ...mapGetters('patientList', ['patientList']),
        ...mapGetters('permissions', ['permissions']),

        hasOrderingPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERING]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                ],
            });
        },

        hasPatientCalculationPermission() {
            return this.checkPermissions({
                [PERMISSIONS.PATIENT_CALCULATIONS]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                ],
            });
        },
        pageName() {
            return {
                [OrderSubStatus.ORDERED]: 'OrderDetails',
                [OrderSubStatus.RESERVED]: 'ReserveDetails',
                [OrderSubStatus.IN_CART]: 'ReserveDetails',
                [OrderSubStatus.CONSUMED]: 'ReserveDetails',
            };
        },
    },

    mounted() {
        this.sortCategory = this.sortCategories.ACTIVITY;
    },

    methods: {
        namesFormatter,
        surgeonIdNameFormat,
        decimalSeparatorFormatter,
        ...mapMutations({
            setShowPatientDetailsPreview: 'patientList/setShowPatientDetailsPreview',
        }),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        filterOrderedLens(lens) {
            if (this.hasOrderingPermission) {
                return true;
            }
            return lens.orderStatus != OrderSubStatus.ORDERED;
        },
        calculationStatus(preOpDataItems, side) {
            const preOpDataItem = preOpDataItems.find((x) => x.opEye == side);
            const labels = {
                [Status.SAVED]: this.t('preOpDataSaved'),
                [Status.CALCULATED]: this.t('targetLensPending'),
            };
            return labels[get(preOpDataItem, 'status')];
        },

        lensRange(dataItem, key, expanded) {
            /* TODO/NOTE: Once the endpoint is updated, this function will actually determine whether to show 1 lens or all lenses, based on the toggle. */
            dataItem = dataItem.filter(
                (lens) =>
                    !Object.values(get(lens, key, {})).every(isEmpty) &&
                    this.filterOrderedLens(lens)
            );
            let maxLength = dataItem.length >= 4 ? 4 : dataItem.length;
            if (maxLength == 0) {
                return [];
            }
            let realLength = expanded ? maxLength : 1;

            let subArray = dataItem.slice(0, realLength);
            return subArray;
        },

        /* Navigate to the main Patient Details page for the patient, starting in edit mode. */
        goToPatientDetailsEditMode(id) {
            this.$router.push({
                name: 'PatientDetails',
                params: {patientId: id, startInEditMode: true},
            });
        },

        /* When one of the sortable columns is clicked:
          - Toggle the order between ascending and descending
          - Set the selected sort category
        */
        toggleSortOrder(columnOrder, name) {
            columnOrder = columnOrder === 'desc' ? 'asc' : 'desc';
            switch (name) {
                case 'patientNameSortOrder':
                    this.patientNameSortOrder = columnOrder;
                    this.queryParams.sortBy = this.sortCategories.PATIENT;
                    break;

                case 'surgeonSortOrder':
                    this.surgeonSortOrder = columnOrder;
                    this.queryParams.sortBy = this.sortCategories.SURGEON;
                    break;

                default:
                    this.activitySortOrder = columnOrder;
                    this.queryParams.sortBy = this.sortCategories.ACTIVITY;
            }
            this.queryParams.sortOrder = columnOrder;
            this.$emit('sort-changed');
        },

        expand(data) {
            data.item.expanded = !data.item.expanded;
            data.item.icon = this.icon(data);
            this.tableDataKey++;
        },

        expandable(patient) {
            return (
                patient.odLensDetails.filter(
                    (l) => !Object.values(l.reservedPrescription).every(isEmpty)
                ).length > 1 ||
                patient.osLensDetails.filter(
                    (l) => !Object.values(l.reservedPrescription).every(isEmpty)
                ).length > 1
            );
        },

        isConsignment(lens) {
            return lens.lensSourceId == InventorySource.CONSIGNMENT;
        },

        allButtonHandler() {
            if (this.allExpanded) {
                this.collapseAll();
            } else {
                this.expandAll();
            }

            this.allExpanded = !this.allExpanded;
            this.tableDataKey++;
        },

        expandAll() {
            //alert("in expand all")
            for (let i = 0; i < this.patientList.length; i++) {
                let data = this.patientList[i];
                data.expanded = true;
                data.icon = 'chevron-up';
            }
        },

        collapseAll() {
            for (let i = 0; i < this.patientList.length; i++) {
                let data = this.patientList[i];
                data.expanded = false;
                data.icon = 'chevron-down';
            }
        },

        icon(data) {
            if (data.item.icon == undefined) {
                return 'chevron-down';
            }

            if (data.item.expanded) {
                return 'chevron-up';
            } else {
                return 'chevron-down';
            }
        },
        /**
         * Determines whether to show the IOD button or not
         *
         * The IOD button is shown when the lens' order status is 'Ordered'
         * (added) or the order status is 'Consumed'
         * and the lens has a serial number.
         *
         * @param {Object} lensDetail - the lens details
         */
        showIodButton(lensDetail) {
            return (
                this.isConsignment(lensDetail) || lensDetail.orderStatus === OrderSubStatus.ORDERED
            );
        },

        /**
         * Determines if the lens is a Spheric or Toric for the IOD/SID button
         *
         * @param {Object} lensDetails - the lens details
         * returns button text for Sperhic or Toric
         */
        isSidButton(lensDetail) {
            return lensDetail.selectedPrescription.cylinder === undefined ? 'SID' : 'IOD';
        },

        /**
         * Handles when the IOD button is clicked
         *
         * @param {Object} lensDetails - the lens details
         */
        iodBtnClicked(lensDetails) {
            this.lensOrderId = lensDetails.lensOrderId.toString();
            this.modalShow = true;
        },
        /**
         * Gets the PreOp dataset ID
         *
         * @param {Array} preOpDataItems - collection of PreOp data items
         * @param {String} eye - the eye to get the dataset for
         */
        getPreOpDataSetId(preOpDataItems, eye) {
            const preOpDataItem = preOpDataItems.find((x) => x.opEye === eye);
            return preOpDataItem?.preOpDataSetId;
        },
        /**
         * Opens a new window with a printable PreOp report
         *
         * @param {String} preOpDataSetId - the dataset ID to get the PreOp
         *   report for
         */
        printReport(preOpDataSetId) {
            const routeData = this.$router.resolve({
                name: 'PreOpReport',
                params: {preOpDataSetId},
            });

            window.open(routeData.href, '_blank');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../../assets/css/variables';
.np {
    padding-top: 4px;
    padding-bottom: 4px;
}
.table-patient-list th {
    color: rgb(133, 132, 132);
    font-weight: 100;
}

.two-line-min {
    min-height: 35px;
}

.lens-nudge {
    margin-top: -3px;
}
.min-h-sub-row {
    min-height: 44px;
}
button.btn-iod {
    font-size: 0.7rem;
    padding: 0.2rem 0.15rem;
    border-radius: 0.25rem;
    margin-top: -0.3rem;
}

button.btn-print {
    display: contents;
    color: $gray-dark;
}
</style>
